import * as React from "react";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import { isPC } from "../util/util";

import Main from "./main";
import Registered from "./registered";
import Tip from "./tip";
import Info from "./info";
import Interview from './interview';
import Preview from './preview';
import Propaganda from './propaganda';
import Login from "./login";
import RadioList from "../components/RadioList";
import Cases from './cases';
import GetTdk from "../components/GetTdk";
import "../assets/less/Base.less";
import "animate.css";

export default class App extends React.Component {
  // componentWillMount() {
  //   if (isPC() && location.host === "m.huisheng-ai.com") {
  //     location.href = "https://www.huisheng-ai.com";
  //   }
  // }

  getTdk(component, hash) {
    
  }
  
  render() {
    return (
        <HashRouter>
          <Switch>
            <Redirect from="/" exact to="/index" />
            <Redirect from="/home/index" exact to="/index" />
            <Route path="/index" exact component={GetTdk(Main)} />
            <Route path="/index/:id" component={GetTdk(Main)} />
            <Route path="/registered" component={GetTdk(Registered)} />
            <Route path="/tip" component={GetTdk(Tip)} />
            <Route path="/info/:id" component={GetTdk(Info)} />
            <Route path='/interview/:uniqueId' component={Interview} />
            <Route path='/propaganda/:uniqueId' component={Propaganda} />
            <Route path='/preview' component={Preview} />
            <Route path="/login" component={GetTdk(Login)} />
            <Route path="/cases" component={GetTdk(Cases)} />
          </Switch>
        </HashRouter>
    );
  }
}
