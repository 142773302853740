import React, {Component} from 'react';
import { Toast } from 'antd-mobile';
import { get } from '../util/util';
import Icon from '../components/Icon';

export default class Tip extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    const { id } = params;

    this.state = {
      id,
      salesInfo: {},
    };
  };

  async componentDidMount() {
    const { id } = this.state;
    const data = await get({
      url: '/site/sales-info',
      data: { id },
    });

    const { status, data: salesInfo, message } = data;
    if (status === 'success') {
      this.setState({
        salesInfo,
      });
    } else {
      location.hash = '#/index';
    }
  };

  render() {
    const { id, salesInfo } = this.state;
    const {
      qrcodeUrl,
      email,
      name,
      phone,
      wechat,
    } = salesInfo;

    return (
      <div className="registered-box">
        <img
          className="registered-logo"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part1_logo.png'}
          onClick={() => location.hash = `#/index/${id}`}
        />
        <div className="info-body">
          <img className="info-title" src={require('../assets/img/info_title.png')} />
          <img className="info-img"src={qrcodeUrl} />
          <div className="info-name">{name || '-'}</div>
          <span className="info-text">Phone：{phone || '-'}</span>
          <span className="info-text">WeChat：{wechat || '-'}</span>
          <span className="info-text">Email：{email || '-'}</span>
        </div>
        <div className="registered-copyright">
          &copy;{new Date().getFullYear()} 武汉鸣鸾信息科技有限公司
        </div>
      </div>
    );
  }
}