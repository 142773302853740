import React from "react";
import emitter from "../../util/events";

export default class RadioItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      currentTime: 0,
    };
  }

  componentDidMount() {
    this.eventEmitter = emitter.addListener("audioPlay", () => {
      const { playing } = this.state;
      if (playing) {
        this.audioEle.pause();
        if (this.Timer) {
          clearInterval(this.Timer);
          this.Timer = null;
        }
        this.setState({
          playing: false,
          currentTime: 0,
        });
      }
    });
  }

  clickRadioBtn() {
    const { playing } = this.state;
    if (playing) {
      this.audioEle.pause();
    } else {
      emitter.emit("audioPlay");
      if (!this.Timer) {
        this.Timer = setInterval(() => {
          this.setState({
            currentTime: this.audioEle ? this.audioEle.currentTime : 0,
          });
        }, 1000);
      }
      this.audioEle.play();
      this.duration = this.audioEle.duration;
    }
    this.setState({ playing: !playing });
  }

  render() {
    const { itemData, listIndex } = this.props;
    const { playing, currentTime } = this.state;

    return (
      <div className="case-radio-item" key={listIndex}>
        <div className="radio-play-box" onClick={this.clickRadioBtn.bind(this)}>
          <img
            className="audio-btn-icon"
            src={require(`../../assets/img/${playing ? "stop" : "start"}.png`)}
            alt=""
            draggable={false}
          />
          <audio
            data-src={itemData.recordFile}
            ref={(node) => (this.audioEle = node)}
            lazyload="true"
          >
            Your browser does not support the audio element.
          </audio>
        </div>
        <div className="case-detail-box">
          <div className="case-info">
            <div className="case-type">
              {(listIndex >= 10 ? listIndex : "0" + listIndex) +
                ". " +
                itemData.type}
            </div>
            <div className="case-time">
              <img src={require(`../../assets/img/time.png`)} alt="" />
              {Math.round(itemData.duration / 1000) + "″"}
            </div>
            {this.Timer ? (
              <div className="case-radio-process">
                <span
                  className="playTime"
                  style={{ width: (currentTime / (this.duration || itemData.duration)) * 88 }}
                ></span>
              </div>
            ) : null}
          </div>
          <div className="tags">
            {itemData.tagsList && itemData.tagsList.length
              ? itemData.tagsList.map((item, index) => {
                  return <span key={index}>{item.tag}</span>;
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}
