import React, {Component} from 'react';
import './style.less';
import PrivateAudio from '../PrivateAudio';

export default class Part4 extends Component {

  render() {
    return (
      <div className="part4-box" id="audition">
        <img
          className="part4-title"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part4_title.png'}
          alt=""
          draggable={false}
        />
        <PrivateAudio
          src="audio1.mp3"
          img="audition_img1.jpg"
          title="汽车售后服务回访示例"
        />
        <PrivateAudio
          src="audio2.mp3"
          img="audition_img2.jpg"
          title="教职工岗位邀约示例"
        />
        <PrivateAudio
          src="audio3.mp3"
          img="audition_img3.jpg"
          title="企业招聘场景示例"
        />
        <a className="more-audio-btn" href="//voice-m.liexing-ai.com/#/cases">更多试听</a>
      </div>
    )
  }
}