import React, {Component} from 'react';
import './style.less';

export default class Part5 extends Component {
  render() {
    return (
      <div className="part5-box">
        <img
          className="part5-title"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part5_title.png'}
          alt=""
          draggable={false}
        />
        <div className="part5-item" style={{ marginTop: '5.3%' }}>
          <img
            className="part5-img"
            src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part5_img1.jpg'}
            alt=""
            draggable={false}
          />
          <p className="item-title">01. 精准意向判断</p>
          <p className="item-text">承诺候选人意向判断准确度95%以上</p>
        </div>
        <div className="part5-item" style={{ marginTop: '4%' }}>
          <img
            className="part5-img"
            src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part5_img2.jpg'}
            alt=""
            draggable={false}
          />
          <p className="item-title">02. 人性化标签</p>
          <p className="item-text">精细化标注客户“是否感兴趣” “是否愿意加微信” “已有工作” “厌恶”</p>
        </div>
        <div className="part5-item" style={{ marginTop: '4%' }}>
          <img
            className="part5-img"
            src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part5_img3.jpg'}
            alt=""
            draggable={false}
          />
          <p className="item-title">03. 姓名发音合成</p>
          <p className="item-text">完美人声与行业话术无缝衔接，实现无感知人机对话</p>
        </div>
        <div className="part5-item" style={{ marginTop: '4%' }}>
          <img
            className="part5-img"
            src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part5_img4.jpg'}
            alt=""
            draggable={false}
          />
          <p className="item-title">04. 专有名词识别</p>
          <p className="item-text">挖掘行业特点，建立专属行业标签数据库</p>
        </div>
      </div>
    )
  }
}