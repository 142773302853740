import queryString from 'query-string';
import React, {Component} from 'react';

import Part1 from '../components/Part1';
import Part2 from '../components/Part2';
import Part3 from '../components/Part3';
import Part4 from '../components/Part4';
import Part5 from '../components/Part5';
import Part6 from '../components/Part6';
import Part7 from '../components/Part7';

export default class Main extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    const { id } = params;

    this.state = {
      id,
      toTop: false,
    };

    this.queryParams = queryString.parse(this.props.location.search);
  };

  componentDidMount() {
    window.addEventListener('resize', this.setPixelRatio, false);
    this.setPixelRatio();

    window.onscroll = this.handleScroll.bind(this);

    const bottomBtn = document.getElementsByClassName('bottom-btn')[0];
    bottomBtn.style.animationName = 'slideInDown';
    bottomBtn.style.animationIterationCount = 'infinite';

    
    const { anchorName } = this.queryParams;
    if (anchorName) {
      this.scrollToAnchor(anchorName);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setPixelRatio);
    document.body.style.zoom = 1;

    window.onscroll = null;
  }

  handleScroll() {
    const scrollTop = Math.ceil(document.documentElement.scrollTop || document.body.scrollTop);
    const bottomBtn = document.getElementsByClassName('bottom-btn')[0];
    if (scrollTop > 50) {
      this.setState({
        toTop: true,
      });
      bottomBtn.style.animationName = 'fadeOutDown';
      bottomBtn.style.animationIterationCount = '1';
    } else {
      this.setState({
        toTop: false,
      });
      bottomBtn.style.animationName = 'slideInDown';
      bottomBtn.style.animationIterationCount = 'infinite';
    }
  }

  scrollToAnchor(anchorName) {
    const offset = 0;
    const main = document.documentElement;
    const { body } = document;
    const anchorEle = document.getElementById(anchorName);
    if (anchorEle) {
      if (window.navigator.userAgent.includes('Edge')) {
        main.scrollTop = Math.ceil(anchorEle.offsetTop - offset);
        body.scrollTop = Math.ceil(anchorEle.offsetTop - offset);
      } else {
        window.scrollTo({
          top: Math.ceil(anchorEle.offsetTop - offset),
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  setPixelRatio() {
    const { width } = window.screen;
    const radio = Math.min(width / 360, 3);
    const { body } = document;
    body.style.zoom = radio;
  }

  clickTopBtn() {
    this.scrollToAnchor('part1-box');
  };

  clickBottomBtn() {
    this.scrollToAnchor('part2-box');
  };

  render() {
    const { id, toTop } = this.state;

    return (
      <React.Fragment>
        <Part1 />
        <Part2 />
        <Part3 />
        <Part4 />
        <Part5 />
        <Part6 />
        <Part7 />
        {
          toTop && (
            <img
              className="top-btn"
              src={require('../assets/img/top.png')}
              onClick={this.clickTopBtn.bind(this)}
            />
          )
        }
        <img
          className="bottom-btn animated"
          src={require('../assets/img/bottom.png')}
          onClick={this.clickBottomBtn.bind(this)}
        />
        {
          !id ?
          (
            <div
              className="bottom-bar"
              onClick={() => location.hash = `#/registered${this.queryParams.bd_vid ? `?bd_vid=${this.queryParams.bd_vid}` : ''}`}
            >
              申请试用
            </div>
          ) :
          (
            <div
              className="bottom-bar"
              onClick={() => location.hash = `#/info/${id}`}
            >
              联系我们
            </div>
          )
        }
      </React.Fragment>
    );
  };
}