import React, {Component} from 'react';
import { Toast } from 'antd-mobile';
import queryString from 'query-string';

import Form from '../components/Form';
import { get, post } from '../util/util';

export default class Registered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceAndCity: [],
      hasGetCode: false,
      remainTime: 60,
      getCodePhone: '',
    };

    if (location.search.indexOf('bd_vid')) {
      this.queryParams = queryString.parse(location.search);
    } else if (this.props.location.search.indexOf('bd_vid')) {
      this.queryParams = queryString.parse(this.props.location.search);
    }
  };

  async componentDidMount() {
    const data = await get({
      url: '/public-register/province-city-list',
    });
    if (data.status === 'success') {
      const { data: { provinceAndCity } } = data;
      this.setState({
        provinceAndCity,
      });
    }
  }

  async handleSubmit(params) {
    const { city: [province, city], phone, phoneCode } = params;

    const { getCodePhone } = this.state;
    if (!getCodePhone) {
      Toast.info('请先获取验证码', 2);
      return;
    }
    if (getCodePhone !== phone) {
      Toast.info('手机号码改变，请重新获取验证码', 2);
      return;
    }
    
    const data = await post({
      url: '/public-register/validate-phone-code',
      data: { phone, phoneCode },
    });
    const { status, message } = data;
    if (status === 'success') {
      const data2 = await post({
        url: '/public-register/register',
        data: {
          ...params,
          province,
          city,
          inviteCode: '',
          bd_vid: this.queryParams.bd_vid
        },
      })
      const { status: stat, message: msg} = data2;
      if (stat === 'success') {
        Toast.success(msg || '注册成功', 2);
        location.hash = '#/tip'
      } else {
        Toast.fail(msg || '注册失败', 2);
      }
    } else {
      Toast.fail(message || '验证码校验失败', 2);
    }
  }

  async clickCodeBtn(phone) {
    const data = await get({
      url: '/public-register/send-phone-code',
      data: {
        phone,
        type: 'register',
      },
    })
    const { status, message } = data;
    if (status === 'success') {
      Toast.success(message || '验证码发送成功', 2);
      this.setState({
        getCodePhone: phone,
        hasGetCode: true,
      });
      const timer = setInterval(() => {
        let { remainTime } = this.state;
        this.setState({
          remainTime: --remainTime,
        });
        if (!remainTime) {
          clearInterval(timer);
          this.setState({
            hasGetCode: false,
            remainTime: 60,
          });
        }
      }, 1000);
    } else {
      Toast.fail(message || '验证码发送失败', 2);
    }
  };

  render() {
    const { provinceAndCity, hasGetCode, remainTime } = this.state;

    return (
      <div className="registered-box">
        <img
          className="registered-logo"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part1_logo.png'}
          onClick={() => location.hash = "#/index"}
        />
        <img className="registered-title" src={require('../assets/img/register_title.png')} />
        <Form
          data={provinceAndCity}
          handleSubmit={this.handleSubmit.bind(this)}
          clickCodeBtn={this.clickCodeBtn.bind(this)}
          code={{ hasGetCode, remainTime }}
        />
        <div className="registered-footer">
          <span className="registered-footer-content">武汉鸣鸾信息科技有限公司</span>
          <span className="registered-footer-content">联系方式：18674040029</span>
          <span className="registered-footer-content">地址：湖北省武汉市洪山区光谷大道未来之光</span>
          <span className="registered-footer-copyright">&copy; Copyright {new Date().getFullYear()}. <a style={{ color: '#a4a8af' }} href="https://beian.miit.gov.cn/" target="__blank">鄂ICP备13001186号-4</a></span>
        </div>
      </div>
    )
  }
}