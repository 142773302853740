import React, {Component} from 'react';
import { get, post } from '../util/util';
import { Toast, List, InputItem, Picker, Checkbox, DatePicker, TextareaItem, Button } from 'antd-mobile';
import { createForm } from 'rc-form';
import moment from 'moment';
import '../assets/less/Interview.less';
const phoneReg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|17[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
const emailReg = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

const CheckboxItem = Checkbox.CheckboxItem;

const Form = createForm()(
  props => {
    const { form, handleSubmit, content = [], hasSubmited, replyInfo, userInfo = {} } = props;
    const { getFieldProps, validateFields } = form;

    const okHandle = (e) => {
      e.preventDefault();
      validateFields((err, fieldsValue) => {
        if (err) {
          Toast.info('存在必填项尚未填写', 2);
          return;
        };

        const newParams = {};
        const keys = Object.keys(fieldsValue);
        keys.map(key => {
          const val = fieldsValue[key];
          if (!key.includes('-')) {
            newParams[key] = val;
          } else {
            if (val) {
              const newKey = key.split('-')[0];
              const oldValue = newParams[newKey];
              if (!oldValue) {
                const arr = [];
                arr.push(key.split('-')[1]);
                newParams[newKey] = arr;
              } else {
                oldValue.push(key.split('-')[1]);
              }
            }
          }
        });
        handleSubmit(newParams);
      });
    };

    const inputBlur = () => {
      if (window.navigator.appName === 'Netscape') {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    };

    return (
      <List className="form-list">
        {
          content.map(item => {
            if (item.type === 'fill') {
              let rawValue = replyInfo[item.key];
              if (phoneReg.test(rawValue)) {
                rawValue = rawValue.slice(0, 3) + '****' + rawValue.slice(-4);
              } else if (emailReg.test(rawValue)) {
                rawValue = rawValue.slice(0, 1) + '****' + rawValue.slice(-4);
              }

              let initialValue;
              if (item.key === 'xingming') {
                initialValue = userInfo.name || undefined;
              } else if (item.key === 'dianhua') {
                initialValue = userInfo.phone || undefined;
              }

              const extraProps = hasSubmited ? {
                value: rawValue,
                disabled: true
              } : {};
              return (
                <InputItem
                  { ...getFieldProps(String(item.configId), {
                    rules: [{required: item.isNecessary === 1}],
                    initialValue,
                  }) }
                  key={item.configId}
                  placeholder="请填写"
                  className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}
                  onBlur={inputBlur}
                  { ...extraProps }
                >
                  {item.name}
                </InputItem>
              );
            }

            if (item.type === 'singleOption' || item.type === 'datePick') {
              const { option } = item;
              option.map(op => {
                op.label = op.week ? op.value + ' (' + op.week + ')' : op.value;
              });

              const extraProps = hasSubmited ? {
                value: [`${replyInfo[item.key]}`],
                disabled: true
              } : {};

              let initialValue;
              if (option.length === 1) {
                initialValue = [option[0].value] || undefined;
              }

              if (hasSubmited) {
                const arr = [];
                option.map(op => {
                  arr.push(op.value);
                });
                if (!arr.includes(replyInfo[item.key])) {
                  option.push({
                    value: replyInfo[item.key],
                    label: replyInfo[item.key],
                  });
                }
              }

              return (
                <div className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`} key={item.configId}>
                  <Picker
                    extra="请选择"
                    cols={1}
                    data={option}
                    format={labels => labels.length && (<span style={{ fontSize: 17, color: `${hasSubmited ? '#bbb' : '#000'}` }}>{labels}</span>)}
                    { ...getFieldProps(String(item.configId), {
                      rules: [{required: item.isNecessary === 1}],
                      initialValue,
                    }) }
                    { ...extraProps }
                  >
                    <List.Item arrow="down">{item.name}</List.Item>
                  </Picker>
                </div>
              );
            }

            if (item.type === 'multipleOption') {
              const { option } = item;
              option.map(op => {
                op.label = op.value;
              });
              
              return (
                <List
                  renderHeader={() => item.name}
                  key={item.configId}
                  className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}
                >
                  {option.map(i => {
                    const extraProps = hasSubmited ? {
                      disabled: true,
                      defaultChecked: replyInfo[item.key].split(',').includes(i.value)
                    } : {};
                    return (
                      <CheckboxItem key={i.value}
                        { ...getFieldProps(String(item.configId) + '-' + i.value) }
                        { ...extraProps }
                      >
                        {i.label}
                      </CheckboxItem>
                    )
                  })}
                </List>
              );
            }

            // if (item.type === 'datePick') {
            //   const now = new Date(Date.now());
            //   return (
            //     <div key={item.configId} className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}>
            //       <DatePicker
            //         value={now}
            //         { ...getFieldProps(String(item.configId), {
            //           rules: [{required: item.isNecessary === 1}],
            //         }) }
            //       >
            //         <List.Item arrow="down">{item.name}</List.Item>
            //       </DatePicker>
            //     </div>
            //   );
            // }

            if (item.type === 'qa') {
              const extraProps = hasSubmited ? {
                value: `${replyInfo[item.key]}`,
                disabled: true
              } : {};
              return (
                <div key={item.configId} className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}>
                  <List renderHeader={() => item.name}>
                    <TextareaItem
                      { ...getFieldProps(String(item.configId), {
                        rules: [{required: item.isNecessary === 1}],
                      }) }
                      rows={5}
                      count={100}
                      style={{ color: '#000', fontSize: 17 }}
                      onBlur={inputBlur}
                      { ...extraProps }
                    />
                  </List>
                </div>
              );
            }
          })
        }
        {
          !hasSubmited ?
          (
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              onClick={okHandle}
            >
              提交
            </Button>
          ) :
          (
            <Button
              style={{ marginTop: 30 }}
              disabled
            >
              已提交
            </Button>
          )
        }
      </List>
    );
  }
)

export default class Interview extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    const { uniqueId } = params;
    this.state = {
      uniqueId,
      interviewInfo: {},
      hasSubmited: false,
    };
  };

  async componentDidMount() {
    const { uniqueId } = this.state;
    const data = await get({
      url: '/site/interview-question',
      data: { uniqueId },
    })
    const { data: info, status } = data;
    if (status === 'success') {
      const { questionInfo: interviewInfo, replyInfo, userInfo = {} } = info;
      this.setState({
        interviewInfo,
        hasSubmited: !(replyInfo instanceof Array),
        replyInfo,
        userInfo,
      });
      if (interviewInfo.questionId === 27) {
        document.title = '抖音电商礼品邮寄信息统计';
      }
    } else {
      Toast.fail('获取面试信息失败，请稍后再试');
    }
  }

  async handleSubmit(params) {
    const keys = Object.keys(params);
    const replyInfo = [];
    keys.map(key => {
      const val = params[key];
      if (val instanceof Date) {
        replyInfo.push({
          configId: parseInt(key, 10),
          value: moment(val).format('YYYY-MM-DD HH:mm'),
        })
      } else if (val instanceof Array) {
        replyInfo.push({
          configId: parseInt(key, 10),
          value: val.join(','),
        })
      } else {
        replyInfo.push({
          configId: parseInt(key, 10),
          value: val,
        })
      }
    });
    const { uniqueId, interviewInfo } = this.state;
    const { questionId } = interviewInfo;
    const data = await post({
      url: '/site/interview-reply',
      data: { uniqueId, questionId, replyInfo },
    });
    const { status } = data;
    if (status === 'success') {
      Toast.success('提交成功', 2);

      const data2 = await get({
        url: '/site/interview-question',
        data: { uniqueId },
      })
      const { data: info, status } = data2;
      if (status === 'success') {
        const { questionInfo: interviewInfo, replyInfo } = info;
        this.setState({
          interviewInfo,
          hasSubmited: !(replyInfo instanceof Array),
          replyInfo,
        });
      }
      this.setState({
        hasSubmited: true,
      });
    } else {
      Toast.fail('提交失败，请稍后再试', 2);
    }
  }

  render() {
    const { interviewInfo, hasSubmited, replyInfo, userInfo } = this.state;
    const { title, coverUrl, content } = interviewInfo;
    return (
      <div className="interview-box">
        {/* <img src={coverUrl || 'https://cdn.huisheng-ai.com/image/m/assets/img/interview_default.png'} /> */}
        <div className="cover-banner">
          <img
            className="cover-img"
            src={coverUrl || 'https://cdn.huisheng-ai.com/image/m/assets/img/interview_default.png'}
          />
        </div>
        <div className="title-banner">
          <img
            className="title-left"
            src="https://cdn.huisheng-ai.com/image/m/assets/img/title_left.png"
          />
          <span className="title-text">{title}</span>
          <img
            className="title-right"
            src="https://cdn.huisheng-ai.com/image/m/assets/img/title_right.png"
          />
        </div>
        <Form
          content={content}
          handleSubmit={this.handleSubmit.bind(this)}
          hasSubmited={hasSubmited}
          replyInfo={replyInfo}
          userInfo={userInfo}
        />
        <div className="box-footer">技术支持： 武汉鸣鸾信息科技有限公司</div>
      </div>
    );
  };
}
