import React from 'react';

class Cases extends React.Component {
  componentWillMount() {
    window.location.href = '//voice-m.liexing-ai.com/#/cases';
  }

  render () {
    return <></>
  }
}

export default Cases;
