import React, {Component, Fragment} from 'react';
import './style.less';
import emitter from '../../util/events';

export default class PrivateAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
  }

  componentDidMount() {
    this.audioEle.addEventListener('loadedmetadata', () => {
      this.setState({
        duration: this.audioEle.duration,
      });
    });

    this.eventEmitter = emitter.addListener('audioPlay', () => {
      const { playing } = this.state;
      if (playing) {
        this.audioEle.pause();
        this.setState({
          playing: false,
        });
      }
    });
  };

  componentWillUnmount() {
    emitter.removeAllListeners(['audioPlay']);
  }

  clickBtn() {
    const { playing } = this.state;
    if (playing) {
      this.audioEle.pause();
    } else {
      emitter.emit('audioPlay');
      this.audioEle.play();
    }
    this.setState({ playing: !playing });
  }

  render() {
    const { img, src, style, title} = this.props;
    const { playing, duration } = this.state;

    return (
      <Fragment>
        <div
          className="audio-box"
          style={style}
          onClick={this.clickBtn.bind(this)}
        >
          <img className="audio-img" src={`https://cdn.huisheng-ai.com/image/m2/assets/img/${img}`} />
          <div className="audio-mask">
            {/* <img
              className="audio-btn"
              src={`https://cdn.huisheng-ai.com/image/m/assets/img/${playing ? 'pause' : 'play'}.png`}
            /> */}
            <div className="audio-btn">
              <img
                className="audio-btn-icon"
                src={require(`../../assets/img/${playing ? 'pause' : 'play'}.png`)}
                alt=""
                draggable={false}
              />
              {playing ? '暂停' : '试听'}
            </div>
          </div>
          <audio
            src={`https://cdn.huisheng-ai.com/image/m2/assets/audio/${src}`}
            ref={(node) => this.audioEle = node}
            >
            Your browser does not support the audio element.
          </audio>
        </div>
        <p className="audio-title">• {title} •</p>
        <p className="audio-subtitle">示例时长：{Math.floor(duration)}秒</p>
      </Fragment>
    )
  }
}