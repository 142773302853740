function request(option) {
  return new Promise((resolve, reject) => {
    option.data = option.data || {}
    let method = option.method || 'GET'
    let data = ''
    if (method === 'POST' && option.data) {
      data = JSON.stringify(option.data)
    }
    if (method === 'GET' && option.data) {
      let arr = []
      for (let key in option.data) {
        arr.push(`${key}=${option.data[key]}`)
      }
      let query = arr.join('&')
      option.url = `${option.url}?${query}`
    }
    let xhr = new XMLHttpRequest()
    xhr.open(method, option.url)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onload = () => {
      try {
        resolve(JSON.parse(xhr.responseText))
      } catch (error) {
        reject(error)
      }
    }
    xhr.send(data)
  })
}

export function get(option) {
  return request({
    ...option,
    method: 'GET'
  })
}

export function post(option) {
  return request({
    ...option,
    method: 'POST'
  })
}

export function isPC(){  
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
  var flag = true;  
  for (var v = 0; v < Agents.length; v++) {  
      if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
  }  
  return flag;  
}

export const getCookie = (cname) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }
  return '';
};

export const setCookie = (cname, cvalue, exdays, extime) => {
  const d = new Date();
  d.setTime(extime || d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toGMTString()}`;
  const domain = 'domain=.huisheng-ai.com';
  document.cookie = `${cname}=${cvalue}; ${domain}; ${expires}`;
};