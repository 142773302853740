import React, {Component} from 'react';
import './style.less';

export default class Part6 extends Component {
  render() {
    return (
      <div className="part6-box">
        <img
          className="part6-title"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part6_title.png'}
          alt=""
          draggable={false}
        />
        <div className="part6-items">
          <div className="part6-item" style={{ marginRight: '3.2%' }}>
            <img
              className="part6-icon"
              src={require('../../assets/img/part6_icon1.png')}
              alt=""
              draggable={false}
            />
            <p className="item-title">产品使用培训</p>
            <p className="item-text">指派专人进行产品的现场使用培训，保障每一位客户都能正常使用。</p>
          </div>
          <div className="part6-item">
            <img
              className="part6-icon"
              src={require('../../assets/img/part6_icon2.png')}
              alt=""
              draggable={false}
            />
            <p className="item-title">话术库录入以及优化</p>
            <p className="item-text">话术数据库建立初始，安排专人协助制作，并给出针对性建议。</p>
          </div>
          <div className="part6-item" style={{ marginRight: '3.2%' }}>
            <img
              className="part6-icon"
              src={require('../../assets/img/part6_icon3.png')}
              alt=""
              draggable={false}
            />
            <p className="item-title">7X24小时保姆式服务</p>
            <p className="item-text">指派专业客服1对1服务，随时协助客户解决遇到的问题。</p>
          </div>
          <div className="part6-item">
            <img
              className="part6-icon"
              src={require('../../assets/img/part6_icon4.png')}
              alt=""
              draggable={false}
            />
            <p className="item-title">后期运维复盘</p>
            <p className="item-text">维护人员上门复盘部署后系统运维情况，并给出优化建议。</p>
          </div>
          <div className="part6-item" style={{ marginRight: '3.2%' }}>
            <img
              className="part6-icon"
              src={require('../../assets/img/part6_icon5.png')}
              alt=""
              draggable={false}
            />
            <p className="item-title">季度健康检查</p>
            <p className="item-text">定期对部署后的系统全面检查，保障系统稳定运行。</p>
          </div>
          <div className="part6-item">
            <img
              className="part6-icon"
              src={require('../../assets/img/part6_icon6.png')}
              alt=""
              draggable={false}
            />
            <p className="item-title">年度系统升级</p>
            <p className="item-text">根据客户需求，对部署系统进行定期升级服务。</p>
          </div>
        </div>
        {/* <p className="part6-copyright">&copy;{new Date().getFullYear()} 武汉鸣鸾信息科技有限公司</p> */}
      </div>
    )
  }
}