import React, { Component } from 'react';

export default class Icon extends Component {
  render () {
    const { type, size, color } = this.props;

    return (
      <i
        className={`iconfont icon-${type}`}
        style={{
          fontSize: `${size || '16px'}`,
          color: color || null,
        }}
      />
    );
  }
}