import React, { Component, Fragment } from 'react';
import { List, InputItem, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import { post, setCookie } from '../util/util';
import '../assets/less/login.less';

export default class Login extends Component {
  componentDidMount() {
    this.userNameInput.focus();
  }

  render() {
    const LoginForm = createForm()(
      props => {
        const { form } = props;
        const { getFieldProps, validateFields } = form;

        const okHandle = (e) => {
          e.preventDefault();
          validateFields((err, fieldsValue) => {
            if (err) {
              Toast.info('用户名或密码未填写');
              return;
            };
            this.handleLogin(fieldsValue);
          });
        };

        return (
          <Fragment>
            <List className="login-list">
              <InputItem
                { ...getFieldProps('username', {
                  rules: [{required: true}],
                }) }
                placeholder="用户名/手机号"
                ref={el => this.userNameInput = el}
              >
                <img
                  src={require('../assets/img/login_username.png')}
                  alt=""
                  draggable={false}
                />
              </InputItem>
              <InputItem
                { ...getFieldProps('password', {
                  rules: [{required: true}],
                }) }
                placeholder="密码"
                type="password"
              >
                <img
                  src={require('../assets/img/login_password.png')}
                  alt=""
                  draggable={false}
                />
              </InputItem>
            </List>
          <div
            onClick={okHandle}
            className="login-submit-btn"
          >
            登录
          </div>
          </Fragment>
        );
      }
    );
    return (
      <div className="login-box">
        <img
          className="login-logo"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part1_logo.png'}
          alt=""
          draggable={false}
          onClick={() => location.hash = "#/index"}
        />
        <LoginForm />
        <div className="login-copyright">
          &copy;{new Date().getFullYear()} 武汉鸣鸾信息科技有限公司
        </div>
      </div>
    );
  }

  async handleLogin(params) {
    const data = await post({
      url: '/site/login',
      data: params,
    });
    const { status, currentAuthority } = data;
    if (status === 'success') {
      setCookie('antdp-authority', currentAuthority, 90);
      window.location.href = 'https://tel.huisheng-ai.com/#/dashboard';
    } else {
      Toast.fail('用户名或密码错误');
    }
  }
}
