import React from "react";
import { Helmet } from "react-helmet";
import { get } from "../util/util";

const GetTdk = (WrappedComponent) =>
  class extends React.PureComponent {
    componentDidMount() {
      const {
        location: { pathname },
      } = this.props;
      get({
        url: "/extra-api/sign-info",
        data: {
          url: `${window.location.origin}${window.location.pathname}`
        }
      })
        .then((res) => {
          const { data } = res;
          if (typeof wx != "undefined") {
              wx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                  appId: data.appId, // 必填，公众号的唯一标识
                  timestamp: data.timestamp, // 必填，生成签名的时间戳
                  nonceStr: data.nonceStr, // 必填，生成签名的随机串
                  signature: data.signature, // 必填，签名，见附录1
                  jsApiList: [
                      // 需要调用的JS接口列表
                      'checkJsApi', // 判断当前客户端版本是否支持指定JS接口
                      'onMenuShareTimeline', // 分享给朋友圈
                      'onMenuShareAppMessage' // 分享到好友
                  ]
              });
              wx.ready(function() {
                  wx.onMenuShareTimeline({
                      title: document.title,
                      link: window.location.href,
                      imgUrl: 'https://cdn.huisheng-ai.com/image/huisheng-logo.png',
                      success: function() {
                          console.log('分享成功');
                      },
                      cancel: function() {
                          console.log('分享失败');
                      }
                  });
                  wx.onMenuShareAppMessage({
                      title: document.title,
                      link: window.location.href,
                      imgUrl: 'https://cdn.huisheng-ai.com/image/huisheng-logo.png',
                      desc: pathname === "/cases" ? '对话过程中动态合成姓名、头衔、公司名、日期、地址、电话号码、车牌号等信息；关键信息抽取形成个性化标签，对结果一目了然。' : '慧声，作为一个新兴的互联网SaaS服务商，以用户体验和产品为核心，突破性地将语音识别技术、大数据分析技术、自然语言处理技术等互联网前沿技术应用于销售行业，经过三年技术攻关，研发上线了“慧声”智能语音AI客服机器人，将销售人员从一些标准化、高度重复并且低价值的工作中解放出来，专注更复杂、更高端、更具价值的事务。切实解决企业产品销售痛点，提高外呼效率，提升客户转化效果。',
                      type: "link",
                      dataUrl: "",
                      success: function() {
                          console.log("分享成功");
                      },
                      cancel: function() {
                          console.log("分享失败");
                      }
                  });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    render() {
      const {
        location: { pathname },
      } = this.props;
      let title = "慧声·智能语音AI客服机器人";
      let keywords =
        "慧声,AI,智能电话机器人,机器人客服,机器人销售,机器人售后,huisheng,企业,传呼,电呼";
      let description =
        "慧声，作为一个新兴的互联网SaaS服务商，以用户体验和产品为核心，突破性地将语音识别技术、大数据分析技术、自然语言处理技术等互联网前沿技术应用于销售行业，经过三年技术攻关，研发上线了“慧声”智能语音AI客服机器人，将销售人员从一些标准化、高度重复并且低价值的工作中解放出来，专注更复杂、更高端、更具价值的事务。切实解决企业产品销售痛点，提高外呼效率，提升客户转化效果。";
      if (pathname === "/cases") {
        title = "慧声-“有温度”的电话机器人";
        description =
          "对话过程中动态合成姓名、头衔、公司名、日期、地址、电话号码、车牌号等信息；关键信息抽取形成个性化标签，对结果一目了然。";
      }

      return (
        <>
          <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords}></meta>
            <meta name="description" content={description}></meta>
          </Helmet>
          <WrappedComponent {...this.props} />
        </>
      );
    }
  };

export default GetTdk;
