import React, {Component} from 'react';
import { get } from '../util/util';
import { Toast } from 'antd-mobile';
import '../assets/less/font.less';
import '../assets/less/Propaganda.less';

export default class Propaganda extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    const { uniqueId } = params;
    this.state = {
      uniqueId,
      currentDesc: {}
    };
  };

  async componentDidMount() {
    const { uniqueId } = this.state;
    const res = await get({
      url: '/site/interview-desc',
      data: { uniqueId }
    });
    const { data, status } = res;
    if (status === 'success') {
      this.setState({
        currentDesc: data,
      });
    } else {
      Toast.fail('获取信息失败', 2);
    }
  }

  downloadFiles() {
    const { currentDesc } = this.state;
    const { fileUrl = [] } = currentDesc;
    fileUrl.map(file => {
      const { name, url } = file;
      const el = document.createElement('a');
      el.href = '/account/' + url.split('/account/')[1];
      el.download = name;
      el.target = '_blank';
      el.click();
    });
  }

  render() {
    const { currentDesc } = this.state;
    const { title, content, fileUrl } = currentDesc;

    return (
      <div className="propaganda-box">
        <p className="propaganda-title">{title}</p>
        <div
          className="propaganda-content"
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
        {
          fileUrl && fileUrl.length !== 0 &&
          fileUrl.map(file => (
            (
              <a
                className="propaganda-download"
                href={file.url.replace(/https?\:\/\/[^\/]+/, '')}
                download={file.name}
              >
                下载附件：{file.name + '.' + file.url.split('.').pop()}
              </a>
            )
          ))
        }
        <p className="propaganda-copyright">技术支持： 武汉鸣鸾信息科技有限公司</p>
      </div>
    );
  };
}
