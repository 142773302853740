import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import App from './screens';

Sentry.init({
    dsn: "https://943447c651434948b29e5a0a08283ef6@sentry.haitou.cc/9",
    tracesSampleRate: 1.0,
  });

ReactDOM.render(<App />, document.querySelector('#app'));
