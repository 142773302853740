import React, {Component} from 'react';
import './style.less';

export default class Part2 extends Component {
  render() {
    return (
      <div className="part2-box" id="part2-box">
        <img
          className="part2-title"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part2_title.png'}
          alt=""
          draggable={false}
        />
        <div className="part2-main">
          <div className="part2-content">
            <img
              className="part2-img"
              src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part2_person.png'}
              alt=""
              draggable={false}
            />
            <div className="part2-text-left">
              <div className="text-header">传统外呼模式</div>
              <div className="text-title" style={{ marginTop: '9.9%' }}>员工成本高</div>
              <div className="text-detail">工资、奖金、招聘、场地等费用高</div>
              <div className="text-title" style={{ marginTop: '11.1%' }}>人员流动大</div>
              <div className="text-detail">受情绪、环境、身体状况影响大，难以控制</div>
              <div className="text-title" style={{ marginTop: '8%' }}>培训时间长</div>
              <div className="text-detail">不能快速上岗，需要经过专业培训</div>
              <div className="text-title" style={{ marginTop: '10.5%' }}>状态跟踪难</div>
              <div className="text-detail" style={{ marginBottom: '21.1%' }}>记录混乱，客户需求意向不明，难以跟进</div>
            </div>
          </div>
          <div className="part2-content">
            <img
              className="part2-img"
              src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part2_robot.png'}
              alt=""
              draggable={false}
            />
            <div className="part2-text-right">
              <div className="text-header">慧声智能语音</div>
              <div className="text-title" style={{ marginTop: '9.9%' }}>低成本</div>
              <div className="text-detail">降低人工、培训成本管理零负担</div>
              <div className="text-title" style={{ marginTop: '11.1%' }}>标准执行</div>
              <div className="text-detail">情绪标准、话术标准、候选人分类标准</div>
              <div className="text-title" style={{ marginTop: '8%' }}>高效率</div>
              <div className="text-detail">海量候选人快速过滤，高效反馈最优候选人</div>
              <div className="text-title" style={{ marginTop: '10.5%' }}>精准数据</div>
              <div className="text-detail" style={{ marginBottom: '9.9%' }}>通话录音全，候选人意向判断准，意向变动更新快</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}