import React from "react";
import { get } from "../../util/util";
import RadioItem from "./radioItem";
import { captureMessage } from "@sentry/react";
import "./style.less";

export default class RadioList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allCasesData: [
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/xyzp.png",
          name: "校园招聘案例",
          title: "校园招聘案例",
          titleEn: "Campus Recruitment",
          caseList: [],
          type: 1,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/dhms.png",
          name: "电话面试",
          title: "电话面试",
          titleEn: "Phone Interview",
          caseList: [],
          type: 2,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/llzp.png",
          name: "蓝领招聘案例",
          title: "蓝领招聘案例",
          titleEn: "Blue-Collar Recruitment",
          caseList: [],
          type: 3,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/blzp.png",
          name: "白领招聘",
          title: "白领招聘",
          titleEn: "White-collar Recruitment",
          caseList: [],
          type: 4,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/yqdy.png",
          name: "疫情调研",
          title: "疫情调研",
          titleEn: "Epidemic Survey",
          caseList: [],
          type: 5,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/shhf.png",
          name: "售后回访",
          title: "售后回访",
          titleEn: "After-sales Revisit",
          caseList: [],
          type: 6,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/hfdy.png",
          name: "toC营销",
          title: "toC营销",
          titleEn: "ToC Marketing",
          caseList: [],
          type: 7,
        },
        {
          avatar: "https://cdn.huisheng-ai.com/image/m2/assets/img/tcyx.png",
          name: "toB营销",
          title: "toB营销",
          titleEn: "ToB Marketing",
          caseList: [],
          type: 8,
        },

      ],
    };
  }

  getDemoList(type) {
    return new Promise((resolve, reject) => {
      get({
        url: "/extra-api/demo-list",
        data: {
          type,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  clickBottomBtn() {
    this.scrollToAnchor("caseItem-1");
  }

  componentWillMount() {
    let newData = Object.assign([], this.state.allCasesData);
    if (false && localStorage && localStorage.getItem("allCasesList")) {
      let allCasesList = JSON.parse(localStorage.getItem("allCasesList"));
      allCasesList.map((item, key) => {
        if (item && item.length) {
          newData[key].caseList = item;
        } else if (item && !item.length) {
          // 有的浏览器将对象转成数组，需要兼容
          let list = [];
          Object.keys(item).map((key) => {
            list.push(item[key]);
          });
          newData[key].caseList = list;
        } else {
          newData[key].caseList = [];
        }
      });
      this.setState({
        allCasesData: newData,
      });
    } else {
      Promise.all(
        this.state.allCasesData.map((item) => this.getDemoList(item.type))
      ).then((res) => {
        res.map((item, key) => {
          newData[key].caseList = item || [];
        });
        this.setState({
          allCasesData: newData,
        });
        localStorage &&
          localStorage.setItem("allCasesList", JSON.stringify(res));
      });
    }
  }

  scrollHandle() {
    let viewHeight = document.documentElement.clientHeight; //获取可视区高度
    let allAudioS = document.querySelectorAll("audio[data-src][lazyload]");
    for (let i = 0; i < allAudioS.length; i++) {
      let item = allAudioS[i];
      if (!item.dataset.src) {
        return;
      }
      let rect = item.parentNode.getBoundingClientRect();
      if (rect.bottom < viewHeight + 500 && rect.top > -50) {
        item.src = item.dataset.src;
        item.removeAttribute("data-src");
        item.removeAttribute("lazyload");
      }
    }

    const scrollTop = Math.ceil(
      document.documentElement.scrollTop || document.body.scrollTop
    );
    const bottomBtn = document.getElementsByClassName("bottom-btn-box")[0];
    if (!bottomBtn) {
      return;
    }
    if (scrollTop > 50) {
      bottomBtn.style.animationName = "fadeOutDown";
      bottomBtn.style.animationIterationCount = "1";
      document.querySelector(".bottom-btn-box").style.display = "none";
    } else {
      bottomBtn.style.animationName = "slideInDown";
      bottomBtn.style.animationIterationCount = "infinite";
      document.querySelector(".bottom-btn-box").style.display = "flex";
    }
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 10);

    this.scrollHandle();
    document.addEventListener("scroll", this.scrollHandle);
    // 动画
    const bottomBtn = document.getElementsByClassName("bottom-btn-box")[0];
    if (bottomBtn) {
      bottomBtn.style.animationName = "slideInDown";
      bottomBtn.style.animationIterationCount = "infinite";
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollHandle);
  }

  scrollToAnchor(anchorName) {
    const offset = 0;
    const main = document.documentElement;
    const { body } = document;
    const anchorEle = document.getElementById(anchorName);
    if (anchorEle) {
      let scrollTop = Math.ceil(anchorEle.offsetTop - offset) - 20;
      if (window.navigator.userAgent.includes("Edge")) {
        main.scrollTop = scrollTop;
        body.scrollTop = scrollTop;
      } else {
        window.scrollTo({
          top: scrollTop,
          left: 0,
        });
      }
    }
  }

  turnToCases(item) {
    this.scrollToAnchor(`caseItem-${item.type}`);
  }

  render() {
    const { allCasesData } = this.state;
    return (
      <>
        <div className="cases-page">
          <div className="cases-header">
            <img
              src={
                "https://cdn.huisheng-ai.com/image/m2/assets/img/part1_logo.png"
              }
              alt=""
              draggable={false}
            />
            <button onClick={() => (location.hash = "#/index")}>
              返回首页
            </button>
          </div>
          <div className="cases-subs">
            <div className="title-box">
              <span className="title-text">案例试听</span>
              <img
                src="https://cdn.huisheng-ai.com/image/m2/assets/img/star.png"
                alt=""
              />
              <span className="desc">APPLICATTION SCENARIO AUDITIO</span>
            </div>
            <div className="cases-sub-content">
              {allCasesData.map((item, index) => {
                return (
                  <div
                    className="cases-sub-item"
                    key={index}
                    onClick={this.turnToCases.bind(this, item)}
                  >
                    <div className="cases-sub-item-box">
                      <img src={item.avatar} alt="" />
                      <span>{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="tips">
              <img
                src="https://cdn.huisheng-ai.com/image/m2/assets/img/quota.png"
                alt=""
                draggable={false}
              />
              <p>
                1.
                动态合成姓名、头衔、公司名、日期、地址、电话号码、车牌号等信息，
                <span>实现“有温度”的沟通</span>。
              </p>
              <p>
                2. 关键信息抽取形成<span>个性化标签</span>，结果一目了然。
              </p>
            </div>
          </div>
          <div className="cases-list">
            {allCasesData.map((item, index) => {
              return (
                <div
                  className="cases-list-item"
                  key={index}
                  id={`caseItem-${item.type}`}
                >
                  <div className="title-box">
                    <span className="title-text">{item.title}</span>
                    <img
                      src="https://cdn.huisheng-ai.com/image/m2/assets/img/star.png"
                      alt=""
                    />
                    <span className="desc">{item.titleEn}</span>
                  </div>
                  <div className="cases-list-item-content">
                    {item.caseList &&
                      item.caseList.length &&
                      item.caseList.map((item, listIndex) => {
                        return (
                          <RadioItem
                            listIndex={listIndex + 1}
                            itemData={item}
                            key={listIndex}
                          />
                        );
                      })}
                    <div className="linear-gradient"></div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="bottom-btn-box animated"
            onClick={this.clickBottomBtn.bind(this)}
          >
            <img
              className="animation-img"
              src={require("../../assets/img/bottom.png")}
            />
          </div>
          <div
            className="bottom-bar"
            onClick={() => (location.hash = "#/registered")}
          >
            申请试用
          </div>
        </div>
      </>
    );
  }
}
