import React, {Component} from 'react';
import { get } from '../util/util';
import { Toast, List, InputItem, Picker, Checkbox, TextareaItem, Button } from 'antd-mobile';
import { createForm } from 'rc-form';
import queryString from 'query-string';
import '../assets/less/Interview.less';

const CheckboxItem = Checkbox.CheckboxItem;

const Form = createForm()(
  props => {
    const { form, content = [] } = props;
    const { getFieldProps } = form;

    const inputBlur = () => {
      if (window.navigator.appName === 'Netscape') {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    };

    return (
      <List className="form-list">
        {
          content.map(item => {
            if (item.type === 'fill') {
              return (
                <InputItem
                  { ...getFieldProps(String(item.name), {
                    rules: [{required: item.isNecessary === 1}],
                  }) }
                  key={item.name}
                  placeholder="请填写"
                  className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}
                  onBlur={inputBlur}
                >
                  {item.name}
                </InputItem>
              );
            }

            if (item.type === 'singleOption' || item.type === 'datePick') {
              const { option = [] } = item;
              option.map(op => {
                op.label = op.week ? op.value + ' (' + op.week + ')' : op.value;
              });
              
              let initialValue;
              if (option.length === 1) {
                initialValue = [option[0].value] || undefined;
              }

              return (
                <div className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`} key={item.name}>
                  <Picker
                    extra="请选择"
                    cols={1}
                    data={option}
                    format={labels => labels.length && (<span style={{ fontSize: 17, color: '#000' }}>{labels}</span>)}
                    { ...getFieldProps(String(item.name), {
                      rules: [{required: item.isNecessary === 1}],
                      initialValue,
                    }) }
                  >
                    <List.Item arrow="down">{item.name}</List.Item>
                  </Picker>
                </div>
              );
            }

            if (item.type === 'multipleOption') {
              const { option } = item;
              option.map(op => {
                op.label = op.value;
              });
              return (
                <List
                  renderHeader={() => item.name}
                  key={item.name}
                  className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}
                >
                  {option.map(i => (
                    <CheckboxItem key={i.value}
                      { ...getFieldProps(String(item.name) + '-' + i.value) }
                    >
                      {i.label}
                    </CheckboxItem>
                  ))}
                </List>
              );
            }

            // if (item.type === 'datePick') {
            //   const now = new Date(Date.now());
            //   return (
            //     <div key={item.name} className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}>
            //       <DatePicker
            //         value={now}
            //         { ...getFieldProps(String(item.name), {
            //           rules: [{required: item.isNecessary === 1}],
            //         }) }
            //       >
            //         <List.Item arrow="down">{item.name}</List.Item>
            //       </DatePicker>
            //     </div>
            //   );
            // }

            if (item.type === 'qa') {
              return (
                <div key={item.name} className={`list-item ${item.isNecessary === 1 ? 'is-necessarry' : ''}`}>
                  <List renderHeader={() => item.name}>
                    <TextareaItem
                      { ...getFieldProps(String(item.name), {
                        rules: [{required: item.isNecessary === 1}],
                      }) }
                      rows={5}
                      count={100}
                      style={{ color: '#000', fontSize: 17 }}
                      onBlur={inputBlur}
                    />
                  </List>
                </div>
              );
            }
          })
        }
        <Button
          type="primary"
          style={{ marginTop: 30 }}
        >
          提交
        </Button>
      </List>
    );
  }
)

export default class Preview extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    const { uniqueId } = params;
    this.state = {
      uniqueId,
      interviewInfo: {},
      hasSubmited: false,
      urlSuffix: queryString.parse(this.props.location.search),
    };
  };

  async componentDidMount() {
    let interviewInfo = {};
    const { urlSuffix } = this.state;
    const keys = Object.keys(urlSuffix);
    if (keys.includes('params')) {
      const { params, coverUrl } = urlSuffix;
      const info = JSON.parse(params);
      const { content, title } = info;
      interviewInfo.coverUrl = coverUrl;
      interviewInfo.title = title;
      interviewInfo.content = content;
    } else if (keys.includes('id')) {
      const { id } = urlSuffix;
      const data = await get({
        url: `/site/interview-question-detail?id=${id}`
      });
      const { data: info, status } = data;
      if (status === 'success') {
        interviewInfo = info;
      } else {
        Toast.fail('获取面试信息失败，请稍后再试');
      }
    }

    this.setState({
      interviewInfo,
    });
  }

  render() {
    const { interviewInfo } = this.state;
    const { title, coverUrl, content } = interviewInfo;

    return (
      <div className="interview-box">
        {/* <img src={coverUrl || 'https://cdn.huisheng-ai.com/image/m/assets/img/interview_default.png'} /> */}
        <div className="cover-banner">
          <img
            className="cover-img"
            src={coverUrl || 'https://cdn.huisheng-ai.com/image/m/assets/img/interview_default.png'}
          />
        </div>
        <div className="title-banner">
          <img
            className="title-left"
            src="https://cdn.huisheng-ai.com/image/m/assets/img/title_left.png"
          />
          <span className="title-text">{title || '面试预约'}</span>
          <img
            className="title-right"
            src="https://cdn.huisheng-ai.com/image/m/assets/img/title_right.png"
          />
        </div>
        <Form content={content} />
        <div className="box-footer">技术支持： 武汉鸣鸾信息科技有限公司</div>
      </div>
    );
  };
}
