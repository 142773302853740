import React from 'react';
import { List, InputItem, WhiteSpace, Picker, Toast, WingBlank } from 'antd-mobile';
import { createForm } from 'rc-form';
import './styles.less';

const Form =  createForm()(
  props => {
    const { form, data, handleSubmit, code, clickCodeBtn } = props;
    const { getFieldProps, validateFields } = form;
    const { hasGetCode, remainTime } = code;

    const okHandle = (e) => {
      e.preventDefault();
      validateFields((err, fieldsValue) => {
        if (err) {
          Toast.info('请完善表单后提交');
          return;
        };

        const phoneReg = /\d{11}$/;
        const passwordReg = /^[a-zA-Z0-9]{6,22}$/;
        const { phone, password } = fieldsValue;

        if (!phoneReg.test(phone)) {
          Toast.info('请输入有效的手机号');
          return;
        }
    
        if (!passwordReg.test(password) || !/[0-9]+/.test(password) || !/[a-zA-Z]+/.test(password)) {
          Toast.info('请输入6-22位密码，必须包含数字和字母');
          return;
        }
        handleSubmit(fieldsValue);
      });
    };

    const codeHandle = (e) => {
      const phoneReg = /\d{11}$/;
      validateFields((err, fieldsValue) => {
        const { phone } = fieldsValue;
        if (!phoneReg.test(phone)) {
          Toast.fail('请输入有效的手机号码');
          return;
        }

        clickCodeBtn(phone);
      });
    };

    const inputBlur = () => {
      if (window.navigator.appName === 'Netscape') {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    };

    return (
      <List className="registered-form">
        <Picker
          className="city-picker"
          extra="请选择您所在的省市"
          cols={2}
          data={data}
          format={labels => labels.length && <span style={{ color: 'black'}}>{labels.join('/')}</span>}
          { ...getFieldProps('city', {
            rules: [{required: true}],
          }) }
        >
          <List.Item arrow="down" />
        </Picker>
        <InputItem
          { ...getFieldProps('account_name', {
            rules: [{ required: true }],
          }) }
          placeholder="请输入机构名"
          onBlur={inputBlur}
        />
        <InputItem
          { ...getFieldProps('name', {
            rules: [{required: true}],
          }) }
          onBlur={inputBlur}
          placeholder="姓名"
        />
        <InputItem
          { ...getFieldProps('phone', {
            rules: [{required: true}],
          }) }
          onBlur={inputBlur}
          placeholder="手机号"
        />
        <InputItem
          { ...getFieldProps('phoneCode', {
            rules: [{required: true}],
          }) }
          onBlur={inputBlur}
          placeholder="验证码"
          className="login-code-input"
        >
          {
            hasGetCode ?
            (
              <span>
                {`${remainTime}s重新获取`}
              </span>
            ) :
            (
              <span
                onClick={codeHandle}
              >
                获取验证码
              </span>
            )
          }
        </InputItem>
        <InputItem
          { ...getFieldProps('password', {
            rules: [{required: true}],
          }) }
          type="password"
          onBlur={inputBlur}
          placeholder="请输入6-22位密码，必须包含数字和字母"
        />
        <WingBlank size="lg" />
        <List.Item
          onClick={okHandle}
          className="submit-btn"
        >
          提交
        </List.Item>
      </List>
    );
  }
)

export default Form;