import React, { Component } from "react";
import "./style.less";

export default class Part1 extends Component {
  render() {
    return (
      <div className="part1-box" id="part1-box">
        <div className="part1-header" onClick={() => location.hash = '#/login'}>
          <span>登录</span>
          <img
            src={require('../../assets/img/login_icon.png')}
            alt=""
            draggable={false}
          />
        </div>
        <div className="part1-main">
          <img
            className="part1-logo"
            src={
              "https://cdn.huisheng-ai.com/image/m2/assets/img/part1_logo.png"
            }
            alt=""
            draggable={false}
          />
          <span className="part1-robot">电话客服 — 产品营销 — 售后回访</span>
          <div className="part1-subTitle">
            <img src="https://cdn.huisheng-ai.com/image/m2/assets/img/right.png" alt="" />
            <span>我们的产品的特点</span>
            <img src="https://cdn.huisheng-ai.com/image/m2/assets/img/right.png" alt="" />
          </div>
          <ul className="part1-subContent">
            <li>
              <img src="https://cdn.huisheng-ai.com/image/m2/assets/img/li1.png" alt="" />
              <p>
                01. 准确度高，评级准确度一般可以到95%以上，<span>承诺如果达不到90%
                退费</span>
              </p>
            </li>
            <li>
              <img src="https://cdn.huisheng-ai.com/image/m2/assets/img/li2.png" alt="" />
              <p>
                02.
                可以合成候选人相关信息，进行“有温度”的沟通，比如合成<span>姓名、头衔、公司名、日期、地址、电话号码、车牌号</span>等信息
              </p>
            </li>
            <li>
              <img src="https://cdn.huisheng-ai.com/image/m2/assets/img/li3.png" alt="" />
              <p>03. 关键信息抽取形成<span>个性化标签</span>，对结果一目了然</p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
