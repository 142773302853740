import React, {Component} from 'react';

export default class Tip extends Component {
  render() {
    return (
      <div className="registered-box" style={{ height: '100%' }}>
        <img
          className="registered-logo"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part1_logo.png'}
          onClick={() => location.hash = "#/index"}
        />
        <div className="tip-body">
          <img className="tip-img" src={require('../assets/img/tip_img.png')} />
          <div className="tip-title">信息提交成功</div>
          <div className="tip-subtitle">我们将于3日内与您联系，请保持手机畅通</div>
        </div>
        <div className="registered-copyright">
          &copy;{new Date().getFullYear()} 武汉鸣鸾信息科技有限公司
        </div>
      </div>
    );
  }
}
