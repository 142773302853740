import React, { Component } from 'react';
import './style.less';

export default class Part7 extends Component {
  render() {
    return (
      <div className="part7-box">
        <img
          className="part7-title"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part7_title.png'}
          alt=""
          draggable={false}
        />
        <span className="part7-content">
          <p className="part7-content-company">武汉鸣鸾信息科技有限公司</p>
          公司成立于2011年，公司主要从事求职服务平台的开发与运营，核心产品为“海投网”、“猎星”及“慧声”。
        </span>
        <div className="part7-footer">
          <span className="part7-footer-content">武汉鸣鸾信息科技有限公司</span>
          <span className="part7-footer-content">联系方式：18674040029</span>
          <span className="part7-footer-content">地址：湖北省武汉市洪山区光谷大道未来之光</span>
          <span className="part7-copyright">&copy; Copyright {new Date().getFullYear()}. <a style={{ color: '#a4a8af' }} href="https://beian.miit.gov.cn/" target="__blank">鄂ICP备13001186号-4</a></span>
        </div>
      </div>
    )
  }
}