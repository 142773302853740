import React, {Component} from 'react';
import './style.less';

export default class Part3 extends Component {
  render() {
    return (
      <div className="part3-box">
        <img
          className="part3-title"
          src={'https://cdn.huisheng-ai.com/image/m2/assets/img/part3_title.png'}
          alt=""
          draggable={false}
        />
        <span className="part3-subtitle">
          急速覆盖意向客户，助力企业产能提升
        </span>
        <div className="part3-items">
          <div className="part3-item">
            <img
              className="part3-img"
              src={require('../../assets/img/part3_img1.png')}
              alt=""
              draggable={false}
            />
            <span className="part3-text-title">自动外呼</span>
            <span className="part3-text-detail">一键录入名单</span>
            <span className="part3-text-detail">智能外呼客户</span>
            <span className="part3-text-detail">自定义外呼频率等设置</span>
          </div>
          <div className="part3-item">
            <img
              className="part3-img"
              src={require('../../assets/img/part3_img2.png')}
              alt=""
              draggable={false}
            />
            <span className="part3-text-title">智能沟通</span>
            <span className="part3-text-detail">真人语音交互</span>
            <span className="part3-text-detail">行业话术应答</span>
            <span className="part3-text-detail">实现无感知人机对话</span>
          </div>
        </div>
        <div className="part3-items">
          <div className="part3-item">
            <img
              className="part3-img"
              src={require('../../assets/img/part3_img3.png')}
              alt=""
              draggable={false}
            />
            <span className="part3-text-title">精准分析</span>
            <span className="part3-text-detail">客户意向自动评级</span>
            <span className="part3-text-detail">人性化标签自动标注</span>
            <span className="part3-text-detail">及时反馈意向变动</span>
          </div>
          <div className="part3-item">
            <img
              className="part3-img"
              src={require('../../assets/img/part3_img4.png')}
              alt=""
              draggable={false}
            />
            <span className="part3-text-title">数据管理</span>
            <span className="part3-text-detail">通话录音拨打数据全纪录</span>
            <span className="part3-text-detail">时长评级标签多维分析</span>
            <span className="part3-text-detail">客户资料永不丢失</span>
          </div>
        </div>
      </div>
    )
  }
}